import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/Default"
import { H2, H3 } from "../components/typography/Headings"
import P from "../components/typography/P"
import Container from "../components/common/Container"
import GridTwo from "../components/common/GridTwo"
import GridThree from "../components/common/GridThree"
import CardImage from "../components/common/CardImage"
import CardJourney from "../components/common/CardJourney"
import CardPrint from "../components/common/CardPrint"
import CardAbout from "../components/common/CardAbout"
import IndexHeader from "../components/sections/IndexHeader"
import Divider from "../components/common/Divider"
import Button from "../components/common/Button"

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="Startseite"
      desc=""
      current="Startseite"
      menuFixed
    >
      <IndexHeader />
      <Container id="entdecken">
        <div className="mb-16 text-center">
          <H2>
            <span className="text-sm font-thin uppercase">Entdecken</span>
          </H2>
          <H3 className="font-display">Erleben Sie die Natur</H3>
        </div>
        <GridTwo>
          <CardImage
            image={data.reise}
            title="Fotoexkursionen / Fotoreisen"
            text="Unsere erlebnisreichen Fototouren stehen unter diesem Motto – und wir möchten Sie dafür begeistern."
            link="/fotoreisen-fotoexkursionen/"
          />{" "}
          <CardImage
            image={data.pubs}
            title="Verlag"
            text="Der Blick von Fotografen, die von Natur aus alles andere als blind für die Natur sind. "
            link="/verlag/"
          />
        </GridTwo>
      </Container>
      <Divider />
      <Container>
        <GridThree>
          <div>
            <H2>
              <span className="text-sm font-thin uppercase">Fotoreisen</span>
            </H2>
            <H3 className="mb-5 font-display">
              Mit uns der Natur auf der Spur!
            </H3>
            <P className="mb-5">
              Unsere NATURBLICK-Referenten begleiten Naturbegeisterte wie Sie
              bei Fotoworkshops, Fotoexkursionen und Fotoreisen und unterstützen
              Sie dabei, Ihren BLICK für die NATUR zu schärfen.
            </P>
            <Button
              text="Alle Fotoreisen"
              to="/fotoreisen-fotoexkursionen/"
            ></Button>
          </div>
          <GridTwo className="col-span-2">
            {data.allFotoreisen.nodes.map((reise, i) => {
              const dates = []
              if (reise?.dates) {
                reise.dates.forEach(date => {
                  dates.push(date.value)
                })
              }
              return (
                <CardJourney
                  image={reise.titleImage}
                  title={reise.title}
                  date={dates}
                  link={`/fotoreisen-fotoexkursionen/${reise.title_slug}`}
                  linkText={
                    reise.type === "Fotoexkursion"
                      ? "Zur Fotoexkursion"
                      : "Zur Fotoreise"
                  }
                  key={i}
                />
              )
            })}
          </GridTwo>
        </GridThree>
      </Container>
      <Divider />
      <Container>
        <div className="max-w-2xl mx-auto mb-16 text-center">
          <H2>
            <span className="text-sm font-thin uppercase">Publikationen</span>
          </H2>
          <H3 className="mb-5 font-display">
            Bei jeder Begegnung mit der Natur kann man mehr finden, als man
            gesucht hat.
          </H3>
          <P className="mb-5">
            Diese Erfahrung macht Peter Scherbuk als suchender Fotograf
            weltweit. Weil er dabei nicht nur beeindruckende Motive, sondern
            auch Menschen mit beeindruckendem Blick für sehenswerte Motive fand,
            gründete er den Verlag NATURBLICK.
          </P>
          <Button text="Alle Publikationen" to="/verlag/"></Button>
        </div>
        <GridThree>
          {data.zeitschriften.nodes.map((pub, i) => {
            return (
              <CardPrint
                image={pub.remoteCoverImage}
                title={pub.title}
                date={pub.date}
                link={`/verlag/zeitschriften/${pub.title_slug}`}
                key={i}
              />
            )
          })}
        </GridThree>
      </Container>
      <Divider />
      <Container>
        <div className="mb-16 text-center">
          <H2>
            <span className="text-sm font-thin uppercase">Über uns</span>
          </H2>
          <H3 className="font-display">Wir stecken hinter NATURBLICK</H3>
        </div>
        <div className="max-w-2xl mx-auto">
          <CardAbout
            image={data.peter}
            title="Peter Scherbuk"
            text="Bereits seit seinem zwölften Lebensjahr beschäftigt er sich mit der Fotografie. Seine Liebe zur Natur hat tiefe Wurzeln, denn er stammt aus einer sehr naturverbundenen ostpreußischen Familie..."
            link="/ueber-uns/"
          />
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    reise: file(relativePath: { eq: "pages/index/fotoreisen.jpg" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1.5)
      }
    }

    pubs: file(relativePath: { eq: "pages/index/pubs.jpg" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1.5)
      }
    }

    peter: file(relativePath: { eq: "pages/index/peter.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    allFotoreisen(limit: 2) {
      nodes {
        dates {
          value
        }
        excerpt
        title_slug
        title
        published
        place
        type
        titleImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 1024
              transformOptions: { cropFocus: ATTENTION }
            )
          }
        }
      }
    }
    zeitschriften: allPublikationen(
      sort: { fields: date, order: DESC }
      limit: 3
      filter: { art: { eq: "Zeitschriften" } }
    ) {
      nodes {
        date
        remoteCoverImage {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { cropFocus: ATTENTION }
              width: 1024
            )
          }
        }
        title
        title_slug
        art
      }
    }
  }
`
