import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H2 } from "../typography/Headings"
import P from "../typography/P"
import { ChevronDoubleRightIcon } from "@heroicons/react/solid"

const CardImage = ({ image, title, text, link, linktext }) => {
  return (
    <div className="relative duration-300 ">
      <Link to={link}>
        <div className="relative group">
          <div className="absolute inset-0 z-10 flex items-center justify-center w-full h-full duration-300 bg-black opacity-0 transistion group-hover:opacity-70">
            <ChevronDoubleRightIcon className="w-6 h-6 text-white" />
          </div>
          <GatsbyImage
            image={getImage(image)}
            alt={title}
            className="relative align-top"
          />
        </div>
      </Link>

      <div className="relative z-10 w-11/12 p-6 mx-auto -mt-6 transition duration-300 bg-white">
        <div className="flex h-full">
          <div className="mt-auto">
            <Link to={link}>
              <H2 className="mb-5 font-display hover:underline text-brand">
                {title}
              </H2>
            </Link>
            <P className="mb-3 text-sm">{text}</P>
            <Link
              to={link}
              className="transition duration-300 text-brand hover:underline"
            >
              {linktext ? linktext : "Weiterlesen"}
              <ChevronDoubleRightIcon className="inline w-4 h-4 ml-2" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardImage
