import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H3 } from "../typography/Headings"
import { ChevronDoubleRightIcon } from "@heroicons/react/solid"

const CardJourney = ({ image, title, date, link, linkText }) => {
  return (
    <div className="">
      <Link to={link}>
        <div className="relative group">
          <div className="absolute inset-0 z-10 flex items-center justify-center w-full h-full duration-300 bg-black opacity-0 transistion group-hover:opacity-70">
            <ChevronDoubleRightIcon className="w-6 h-6 text-white" />
          </div>
          <GatsbyImage
            image={getImage(image)}
            alt={title}
            className="relative align-top"
          />
        </div>
      </Link>

      <div className="relative z-10 w-11/12 p-3 mx-auto -mt-6 bg-white">
        <div className="space-y-3">
          <div>
            <Link
              to={link}
              className="transition-colors duration-300 hover:text-brand"
            >
              <H3 className="mb-3 font-display">
                <span className="text-lg">{title}</span>
              </H3>
            </Link>
          </div>
          {date && (
            <div className="font-display">
              {Array.isArray(date) ? (
                date.map((dat, i) => {
                  return (
                    <div
                      className="inline-block p-1 mb-1 mr-1 text-xs bg-gray-100 rounded"
                      key={i}
                    >
                      {dat}
                    </div>
                  )
                })
              ) : (
                <div className="inline-block p-1 text-xs bg-gray-100 rounded">
                  {date}
                </div>
              )}
            </div>
          )}
          <div>
            <Link
              to={link}
              className="transition-colors duration-300 hover:text-brand"
            >
              {linkText ? linkText : "Zur Fotoreise"}
              <ChevronDoubleRightIcon className="inline w-4 h-4 ml-2" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardJourney
