import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../common/Button"
import { H1 } from "../typography/Headings"
import styled, { keyframes } from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const kenburnAnimation = keyframes`
  0% {
    transform-origin: bottom left;
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
`
const HeaderImage = styled(GatsbyImage)`
  animation: ${kenburnAnimation} 80s ease infinite;
`

const scrollAnimaion = keyframes`
	0% {
		opacity: 0;
		transform: translateY(0);
	}
	5% {
		opacity: 1;
		transform: translateY(0);
	}
	25% {
		opacity: 1;
		transform: translateY(0);
	}
  50% {
    opacity: 0;
    transform: translateY(15px);
  }
  75% {
    opacity: 1;
    transform: translateY(0);
  }
  95% {
    opacity: 1;
    transform: translateY(0);
  }
	100% {
		opacity: 0;
		transform: translateY(0);
	}
`

const IconScroll = styled.div`
  position: absolute;
  left: 50%;
  width: 16px;
  height: 40px;
  margin-left: -8px;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 100px;
  cursor: pointer;

  &:before {
    content: "";
    width: 2px;
    height: 15px;
    background: #fff;
    margin-left: -1px;
    top: 8px;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    animation-name: ${scrollAnimaion};
    position: absolute;
    left: 50%;
  }
`

const IndexHeader = () => {
  const data = useStaticQuery(graphql`
    {
      header: file(relativePath: { eq: "pages/index/header.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 1600, placeholder: BLURRED)
        }
      }
    }
  `)

  return (
    <div className="relative h-screen overflow-hidden">
      <HeaderImage
        image={getImage(data.header)}
        className="absolute inset-0 object-cover w-full h-full"
        alt="Nauturblick Header"
      />
      <div className="absolute inset-0 object-cover w-full h-full bg-black bg-opacity-30"></div>
      <div className="absolute inset-0 flex flex-col items-center justify-center h-screen bg-black bg-opacity-30">
        <div className="max-w-4xl px-4 mx-auto text-center text-white">
          <H1 display className="mb-8">
            Reisen in die Natur für Fotografie-Begeisterte!
          </H1>
          <div className="flex flex-wrap justify-center gap-3">
            <Button
              text="Fotoreisen"
              to="/fotoreisen-fotoexkursionen/"
              transparent
            />
            <Button text="Verlag" to="/verlag/" transparent />
          </div>
        </div>
        <div className="absolute bottom-24 ">
          <AnchorLink
            to="/#entdecken"
            title="Entdecken"
            lassName="relative py-3"
          >
            <IconScroll />
          </AnchorLink>
        </div>
      </div>
    </div>
  )
}

export default IndexHeader
